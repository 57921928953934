import React, { useEffect, useState } from "react";
import backArrow from "../../images/backArrow.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import axiosClientEncryption from "../../utils/axiosClientEncrytion";
import downloadIcon from "../../images/Vector.png";
import { config, getCurrentConfigPhase } from "../../utilities/config";
import {  isWithinInterval } from "date-fns";

const QuarterlyStatement = () => {
  const currentPhase =  getCurrentConfigPhase(new Date(), config);

  const navigate = useNavigate();
  const moment = require("moment");
  const [retailerInfo, setRetailerInfo] = useState([]);
  const [totalEntitlement, setTotalEntitlement] = useState([]);


  const [showQuarterlyEntitlement, setShowQuarterlyEntitlement] =useState(false)

  useEffect(() => {
    const fetchData = async () => {
      /* The `showQuarterlyEntitlement` state variable is used to conditionally render different
      content in the `QuarterlyStatement` component. When `showQuarterlyEntitlement` is `true`,
      it displays the quarterly statement information including total entitlement, statement ID,
      date, and a table of earned amounts. When `showQuarterlyEntitlement` is `false`, it
      displays a message indicating that the statement is not available at the moment and prompts
      the user to check back later. This state variable controls the visibility of the quarterly
      statement content based on its value. */
      try{
        const profileRes = await axiosClientEncryption.get("/retailer/profile");
        setRetailerInfo(profileRes?.data?.retailerIds);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchCurrentCampaign = async () => {
      try {
        if (!currentPhase) {
          console.error("Current phase config not found");
        }
        const campaign = await axiosClientEncryption.get("/campaigns/" + currentPhase.campaignId);
        const entitlementReportPeriod = campaign?.data?.config?.entitlementReportPeriod
        if (isWithinInterval(new Date(), {
          start: new Date(entitlementReportPeriod.startAt),
          end: new Date(entitlementReportPeriod.endAt),
        }) 
      ) {
          setShowQuarterlyEntitlement(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchCurrentCampaign();
    fetchData();
  }, []);
  console.log('showQuarterlyEntitlement',showQuarterlyEntitlement)


  useEffect(() => {
    const fetchEntitlement = async () => {
      try {
        if (!currentPhase) {
          throw new Error("Current phase config not found");
        }

        const campaignId = currentPhase.campaignId;
        const prevCampaignId = currentPhase.prevCampaignId;

        const checkTotalEntitlementRes = await axiosClientEncryption.get(
          `/haleon/retailer/quarterly-entitlement`, {
            params: {
              retailerId: retailerInfo,
              campaignId: campaignId,
              prevCampaignId: prevCampaignId,
            },
          }
        );
        if (checkTotalEntitlementRes?.data?.data) {
          setTotalEntitlement(checkTotalEntitlementRes?.data?.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchEntitlement();
  }, []);

  useEffect(() => {}, [totalEntitlement]);

  // statement total entitlement
  const renderStatementInfo = () => {
    let totalEntitlementAmount = 0;
    let statementId = null;
    if (totalEntitlement && totalEntitlement.length === 1) {
      const data = totalEntitlement[0];
      return {
        totalEntitlementAmount: data.currentQuarterlyData?.totalEntitlementAmount,
        statementId:data.currentQuarterlyData?.statementId,
      };
    }

    return {
      totalEntitlementAmount,
      statementId,
    };
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      {showQuarterlyEntitlement ? (
        <div className="mt-2 ml-2 mr-2 mt-3" style={{ marginBottom: "30px" }}>
          <div style={{ justifyContent: "left" }}>
            <img
              src={backArrow}
              style={{ width: "20px" }}
              alt=""
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="mt-3">
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <h2 className="text-center" style={{ fontSize: "24px" }}>
                Quarterly Statement
              </h2>
              <img
                src={downloadIcon}
                alt=""
                style={{ width: "5%", objectFit: "contain" }}
              />
            </div>
            <p className="text-center fontSize-18 mt-2">
              {totalEntitlement[0]?.retailer?.channel}
            </p>
          </div>

          {totalEntitlement && totalEntitlement.length ? (
            <>
              <div style={{ paddingBottom: "10%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    textAlign: "center",
                  }}
                  className="mt-2"
                >
                  <div>
                    <p
                      style={{
                        fontWeight: "0",
                        textAlign: "center",
                        color: "#444",
                      }}
                    >
                      Total
                    </p>
                    <span style={{ fontWeight: "700", fontSize: "12px" }}>
                      RM {renderStatementInfo().totalEntitlementAmount}
                    </span>
                  </div>
                  <div>
                    <p
                      style={{
                        fontWeight: "0",
                        textAlign: "center",
                        color: "#444",
                      }}
                    >
                      Statement ID
                    </p>
                    <span style={{ fontWeight: "700", fontSize: "12px" }}>
                      {renderStatementInfo().statementId}
                    </span>
                  </div>
                  <div>
                    <p
                      style={{
                        fontWeight: "0",
                        textAlign: "center",
                        color: "#444",
                      }}
                    >
                      Date
                    </p>
                    <span style={{ fontWeight: "700", fontSize: "12px" }}>
                      {moment(new Date()).format("DD MMM YYYY")}
                    </span>
                  </div>
                </div>

                <div className="mt-2">
                  <table className="statement-table-form">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Item</th>
                        <th>Amount Earned</th>
                      </tr>
                    </thead>
                    <tbody>
                      {totalEntitlement.map((entitlementInfo, idx) => {
                        return (
                          <tr key={idx}>
                              <>
                                {" "}
                              <td>{moment(entitlementInfo?.currentQuarterlyData?.dateRange?.startFrom).format("DD-MM-YY") }/{ moment(entitlementInfo?.currentQuarterlyData?.dateRange?.endAt).format("DD-MM-YY")}</td>
                                <td>{entitlementInfo.retailer.name}</td>
                                <td style={{ color: "#45C925" }}>
                                  + RM
                                  {
                                    entitlementInfo.currentQuarterlyData
                                      ?.totalEntitlementAmount
                                  }
                                </td>
                              </>


                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    className="ml-2 mr-2 mt-1"
                  >
                    <span style={{ textAlign: "left" }}>Total</span>
                    <span style={{ textAlign: "right", fontWeight: "900" }}>
                      RM {renderStatementInfo().totalEntitlementAmount}
                    </span>
                  </div>
                </div>
                <Button
                  className="print-statement-button mt-3 fontSize-18"
                  onClick={handlePrint}
                >
                  Print Statement
                </Button>
              </div>
            </>
          ) : (
            <p className="text-center">Loading...</p>
          )}
        </div>
      ) : (
        <div style={{ position: "relative" }}>
        <div style={{ display: "flex", justifyContent: "left" }}>
          <img
            src={backArrow}
            style={{ width: "20px", paddingLeft: "10%", paddingTop: "10%" }}
            alt=""
            onClick={() => navigate(-1)}
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: "250%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: "100%",
            paddingBottom: "10%",
          }}
        >
          <h3 className="text-center mt-3">Statement not available for now</h3>
          <h4>Please check back again later.</h4>
        </div>
      </div>
      
      )}
    </>
  );
};

export default QuarterlyStatement;
